<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Month</label>
                <select class="form-control" v-model="state.optionFields.month.val">
                    <option v-for="[id, month] in helpers.DateHelpers.getMonthsArray()" v-bind:key="id" :value="id">{{ month }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Year</label>
                <select class="form-control" v-model="state.optionFields.year.val">
                    <option v-for="year in helpers.DateHelpers.getYearRangeArray(new Date().getFullYear() - 2, new Date().getFullYear() + 3 )" v-bind:key="year" :value="year">{{ year }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox v-model="state.optionFields.cdiLocation.val" :true-value="1" :false-value=null > CDI Location</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox v-model="state.optionFields.includeCdiStock.val" :true-value="1" :false-value=null > Include CDI Stock</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/Report.store.js";
    import DateHelpers from "@/store/DateHelpers";
    export default {
        name: 'Options',
        data() {
            return {
                state: store.state,
                helpers: {
                    DateHelpers
                }
            }
        }
    }
</script>